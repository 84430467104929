<template>
    <header>
        <div class="private-logo">
            <div class="ui small image">
                <img style="max-height: 68px;width: inherit" src="@/assets/images/fsjes_agdal_logo.png" alt="minister logo">
            </div>
            <div class="ui small image">
                <img src="@/assets/images/um5logo.png" alt="minister logo">
            </div>
        </div>
        <div class="hamburger" @click="showDropdown = !showDropdown">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </div>
        <nav class="nav-bar" :class="{active:showDropdown}">
            <ul>
                <li v-if="current_process === this.$SHARED.process.etude">
                  <router-link class="link" :to="{name:'espace-etude'}">
                    Espace d'étude
                  </router-link>
                </li>
                <li v-else-if="current_process === this.$SHARED.process.reinscription">
                    <router-link class="link"
                                 :to="{name:'espace-reinscription'}">Espace de réinscription
                    </router-link>
                </li>
                <li v-else >
                  <router-link class="link" :to="{name:'dossier-upload'}">Dépôt du dossier</router-link>
                </li>
                <li  v-if="current_process === this.$SHARED.process.etude" >
                  <router-link class="link" :is="(dossier && dossier[this.$SHARED.process.etude]) ? 'router-link' : 'a'"
                              :to="{name:'suivi-etude'}">Suivi des études
                  </router-link>
                </li>
                <li  v-else-if="current_process === this.$SHARED.process.reinscription" >
                  <router-link :is="(dossier && dossier[this.$SHARED.process.reinscription] && dossier[this.$SHARED.process.reinscription].current_step) ? 'router-link' : 'a'"
                               :to="{name:'suivi-reinscription'}">Suivi de demande
                  </router-link>
                </li>
                <li v-else >
                  <router-link class="link"
                               :is="(dossier && dossier[this.$SHARED.process.inscription]) ? 'router-link' : 'a'"
                                :to="{name:'suivi-demande'}">Suivi des demandes
                  </router-link>
                </li>
                <li>
                  <router-link event class="link disabled" :to="{name:'depo-document'}">Dépôt des documents</router-link>
                </li>
                <li>
                  <router-link event class="link disabled" :to="{name:'telechargement-document'}">Téléchargement des documents</router-link>
                </li>
                <li>
                  <router-link event class="link disabled" :to="{name:'gestion-soutenance'}">Demande de soutenance</router-link>
                </li>
                <li>
                    <div class="user-link">
                       
                            <div class="username">{{ currentUser.nom }} {{currentUser.prenom}}</div>
                            <div class="avatara">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 .001c-6.627 0-12 5.372-12 12C0 18.627 5.373 24 12 24c6.628 0 12-5.372 12-12C24 5.373 18.628.001 12 .001Zm0 3.588a3.97 3.97 0 1 1 0 7.939 3.97 3.97 0 0 1 0-7.939Zm-.003 17.274a8.807 8.807 0 0 1-5.734-2.115 1.691 1.691 0 0 1-.594-1.286 4.003 4.003 0 0 1 4.022-4.002h4.619a3.997 3.997 0 0 1 4.016 4.002c0 .495-.216.964-.593 1.285a8.804 8.804 0 0 1-5.736 2.116Z"
                                        fill="#fff"/>
                                </svg>
                            </div>
                            <div class="ui dropdown item">
                                <i class="dropdown icon"></i>
                                <div class="menu">
                                    <a  @click='()=>$modal.show("update-password")' class="item">Changer mot de passe</a>
                                    <a @click="logout" class="item">Se déconnecter</a>
                                    
                                </div>
                            </div>
                         
                        </div>
                </li>
            </ul>
        </nav>

        <update-password />
    </header>
</template>

<script>
    import router from "../router";
    import updatePassword from "@/components/update-password";

    export default {
        components: { updatePassword },
        data() {
            return {
                showDropdown:null,
                process: ''
            }
        },
        computed: {
            currentUser() {
                return this.$store.state.current_user
            },
            dossier() {
                return this.$store.state.dossier
            },
            current_process() {
                return this.$store.state.current_process
            }

        },
        watch: {
            current_process() {
                //this.getProcess()
                this.goTo(this.current_process)
            }
        },
        methods: {
            logout() {
                console.log("`logout`");
                this.$store.dispatchAsync("/logout", {}).then(() => {
                    this.$store.commit("SET_CURRENT_USER", {});
                    this.$router.push("/");
                });
            },
            goTo(process) {
        switch (process) {
            case this.$SHARED.process.etude :
                router.push({name: "espace-etude"});
                break;
            case this.$SHARED.process.reinscription :
                router.push({name: "espace-reinscription"});
                break;
            default:
                router.push({name: "dossier-upload"});
                break;
        }

    },
            getProcess() {
                console.log("getProcess ....", this.dossier)

                if (_.isEmpty(this.dossier)) {
                    console.log("dossier empty ==> process inscription")
                    this.process = this.$SHARED.process.inscription
                } else if (this.dossier.reinscription && this.dossier.reinscription.status && this.dossier.reinscription.status === 'validated') {
                    console.log("student already reinscrit ==> process etude")
                    this.process = this.$SHARED.process.etude
                } else if (this.dossier.reinscription) {
                    console.log("student not reinscrit ==> process reinscription")
                    this.process = this.$SHARED.process.reinscription
                } else if (this.dossier.inscription && this.dossier.inscription.status && this.dossier.inscription.status === 'validated') {
                    console.log("student already inscrit ==> process etude")
                    this.process = this.$SHARED.process.etude
                } else {
                    console.log("==> process inscription")
                    this.process = this.$SHARED.process.inscription
                }
                this.$store.state.current_process = this.process
                console.log("getProcess ==> ", this.process)
            }
        },
        mounted() {
           // this.goTo(this.current_process)
        }
    }
</script>

<style scoped>
header{
    widows: 100%;
    height: 80px;
    background: var(--main-color-blue);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2em 0 0;
}
.private-logo{
    display: flex;
    height: 100%;
    align-items: center;
    background-color: white;
    padding: 0 3em 0 0;
}
.hamburger{
    display: none;
}
.nav-bar ul{
    display: flex;
    list-style: none;
    text-decoration: none;
}
.nav-bar ul li a{
    display: block;
    color:white;
    font-size: 0.95em;
    padding: 10px 15px;
    border-radius: 999px;
    transition: 0.2s;
    margin: 0 5px;

}
.nav-bar ul li a:hover, .nav-bar ul li a.active{
    background-color: var(--main-color-orange);
    color:white;

}
.user-link{
    background-color: var(--main-color-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    padding: 0.5em 1em;
}
.user-link >.username{
    font-weight: 700;
    margin-right: 0.25em;
    color: white;
}
.user{
  position:relative;
  display:flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background: yellow;
}
.router-link-exact-active{
    background-color: var(--main-color-orange);
    color:#ffffff;
}
@media only screen and (max-width: 1320px) {
    header { 
        padding: 0 2em 0 0;
    }
}
@media only screen and (max-width: 900px) {
    .hamburger { 
        display: block;
        cursor: pointer;
    }
    .hamburger .line{
        width:30px;
        height: 3px;
        background: #fefefe;
        margin: 6px 0;
    }
    .nav-bar{
        height: 0;
        position: absolute;
        z-index: 999;
        top:80px;
        left: 0;
        right: 0;
        width: 100vw;
        background-color: var(--main-color-blue);
        transition: 0.2s;
        overflow: hidden;
    }
    .nav-bar.active{
        height: 450px;
        
    }
    .nav-bar ul {
        display: block;
        width: fit-content;
        margin: 80px auto 0 auto;
        text-align: center;
        padding: 0;
        transition: .5s;
        opacity: 0;
    }
    .nav-bar.active ul{
        opacity: 1;
        
    }
    .nav-bar ul  li a{
        margin-bottom: 0.9em;
    }
    .private-logo img { 
        height: 59px;
    }
}
</style>