<template>
	<div id="private">
		<v-dialog name="dialog" :clickToClose="false" transition="nice-modal-fade"/>
		<navbar />
		<div id="private-content">

			<!--loader-->
			<div :class="LOADING?'ui active inverted dimmer':'ui dimmer'">
				<div class="ui big text loader">
					Loading...
				</div>
			</div>

			<!--route-transition-animation-->
			<transition name="fade" mode="out-in">
				<!--current-route-component-->
				<router-view :key="$route.fullPath" style="margin-top: 3em"></router-view>
			</transition>

		</div>

	</div>
</template>

<script>
	import navbar from "@/components/navbar.vue"

	export default {
		name: "Private",
		data() {
			return {};
		},
		components: {
			navbar,
		},
		computed: {
			LOADING() {
				return this.$store.state.loading;
			},
			USER() {
				return this.$store.state.current_user;
			}
		},
		methods: {
			logout() {
				log("`logout`");
				this.$store.dispatchAsync('/logout', {}).then(() => {
					this.$store.commit('SET_CURRENT_USER', {});
					this.$router.push("/");
				});
			},

		},
		mounted() {
			$('.ui.dropdown').dropdown();
			this.$store.dispatch('initApp')
		}
	}
</script>

<style>

</style>
