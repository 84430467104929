<template>
    <modal name="update-password" height="auto" width="520px" :adaptive="true" :pivotY="0.3" :clickToClose="false">
        <h2 class="title"> Changer votre mot de passe </h2>
        <div class="ui form" id="form" style="margin: 10px 20px;">
            <div class="required field">
                <label class="label"> Entrer votre ancien mot de passe </label>
                <input type="password" v-model.trim="$v.user_info.old_pass.$model"/>

                <!-- error message -->
                <div class="ui negative message" v-if="$v.user_info.old_pass.$error">
                    <p> <i class="info circle icon"></i> ancien mot de passe est obligatoire </p>
                </div>
            </div>

            <div class="required field">
                <label class="label"> Entrer votre nouveau mot de passe </label>
                <input type="password" v-model.trim="$v.user_info.new_pass.$model" />

                <!-- error message -->
                <div class="ui negative message" v-if="$v.user_info.new_pass.$error">
                    <p v-if="!$v.user_info.new_pass.required">
                        <i class="info circle icon"></i>nouveau mot de passe est obligatoire
                    </p>
                    <p v-else-if="!$v.user_info.new_pass.strongPassword">
                        <i class="info circle icon"></i>
                        Le nouveau mot de passe doit comporter au moins 8 caractères, un chiffre et une lettre majuscule.
                    </p>
                </div>
            </div>

            <div class="required field">
                <label class="label"> Confirmer votre nouveau mot de passe </label>
                <input type="password" v-model="$v.confirm_pass.$model" />

                <!-- error message -->
                <div class="ui negative message" v-if="$v.confirm_pass.$error">
                    <p> <i class="info circle icon"></i> Les mots de passe ne correspondent pas. </p>
                </div>
            </div>

        </div>
        <div class="ui two bottom attached buttons action-buttons">
            <button class="ui green basic white button" @click="save" >
                    <!--:disabled="!this.user_info.old_pass || !this.user_info.new_pass || !this.confirm_pass"-->

                Modifier
            </button>
            <button class="ui red basic button" @click.prevent="$modal.hide('update-password')" :disabled="current_user.first_login">
                Annuler
            </button>
        </div>
    </modal>
</template>

<script>
    import {required, sameAs} from 'vuelidate/lib/validators'

    export default {
        name: "update-password",
        data(){
            return{
                user_info: {
                    old_pass: null,
                    new_pass: null
                },
                confirm_pass: null
            }
        },
        validations(){
            return {
                user_info: {
                    old_pass: {required},
                    new_pass: {
                        required,
                        strongPassword(new_pass) {
                            return (
                                /[a-z]/.test(new_pass) &&
                                /[A-Z]/.test(new_pass) &&
                                /[0-9]/.test(new_pass) &&
                                new_pass.length >= 8
                            );
                        }
                    }
                },
                confirm_pass: {
                    sameAsPassword: sameAs(()=>{return this.user_info.new_pass})
                }
            }
        },
        methods:{
            save() {
                log('change pass:');
                this.$v.$touch();
                if (this.$v.$invalid)
                    return;

                this.$store.dispatchAsync(this.$SHARED.services.etudiant.changePassword, {
                  ...this.user_info,
                  user_id: this.current_user._id
                }).then(data => {
                    this.$modal.hide('update-password')
                    this.clearFields()
                    this.$notify({
                        group: 'user-message',
                        type: 'success', // warn , error, success, info
                        text: this.$SHARED.messages.user.update_succeeded
                    });
                    // if(this.$store.state.current_user.first_login){
                    //     let current_user = this.$store.state.current_user
                    //     current_user.first_login = false
                    //     this.$store.commit("SET_CURRENT_USER", current_user)
                    // }
                });
            },
            clearFields(){
                this.user_info = {
                    old_pass: null,
                    new_pass: null
                }
                this.confirm_pass = null
            }
        },
        computed:{
            current_user(){
                return this.$store.state.current_user;
            }
        }
    }
</script>

<style scoped>
    .title {
        text-align: center;
        font-size: 1.8em;
        line-height: 68px;
        text-transform: uppercase;
        border-radius: 10px;
        padding: 0.1em 0.4em;
        color: var(--main-color-blue);
        margin: 0;
        font-weight: 700;
    }
    .label{
        font-size: 14px !important;
    }
    .action-buttons{
        margin-top: 30px !important;
    }
</style>